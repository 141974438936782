$colours: (
  white: white,
  brand-blue: $colour-brand-blue,
  notification-type-other: #33b5e5,
  notification-type-release: $colour-feedback-confirmation,
  notification-type-service-window: $colour-feedback-warning,
  notification-type-issue: $colour-feedback-error,
  feedback-error: $colour-feedback-error,
  grey-100: $colour-neutral-grey-100,
  grey-200: $colour-neutral-grey-200,
  grey-300: $colour-neutral-grey-300,
  grey-500: $colour-neutral-grey-500,
  grey-700: $colour-neutral-grey-700,
  grey-900: $colour-neutral-grey-900,
);

$text-colour-dark: map-get($colours, "grey-900");
$text-colour: map-get($colours, "grey-700");

$breakpoints: (
  s: 0px,
  m: 600px,
  l: 900px,
  xl: 1200px,
);

$spacings: (
  0: 0,
  25: 0.25rem,
  50: 0.5rem,
  75: 0.75rem,
  100: 1rem,
  125: 1.25rem,
  150: 1.5rem,
  200: 2rem,
  250: 2.5rem,
  300: 3rem,
  400: 4rem,
  550: 5.5rem,
  750: 7.5rem,
  1000: 10rem,
  1350: 13.5rem,
);

$column-sizes: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12);
