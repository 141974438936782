.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

@mixin position($positioning, $name, $left, $top) {
  .#{$positioning}--#{$name} {
    position: $positioning;

    top: $top;
    left: $left;
    transform: translate(-#{$left}, -#{$top});
  }

  @each $breakpoint-name, $breakpoint in $breakpoints {
    @media (min-width: $breakpoint) {
      .#{$positioning}--#{$breakpoint-name}-#{$name} {
        position: $positioning;

        top: $top;
        left: $left;
        transform: translate(-#{$left}, -#{$top});
      }
    }
  }
}

@include position(absolute, "top-middle", 50%, 0);
@include position(absolute, "top-right", 100%, 0);
@include position(absolute, "middle-right", 100%, 50%);
@include position(absolute, "bottom-right", 100%, 100%);
@include position(absolute, "bottom-middle", 50%, 100%);
@include position(absolute, "bottom-left", 0, 100%);
@include position(absolute, "middle-left", 0, 50%);
@include position(absolute, "top-left", 0, 0);
@include position(fixed, "bottom-right", 100%, 100%);
@include position(fixed, "middle-left", 0, 50%);
