@each $colour-name, $colour in $colours {
  .text--#{$colour-name} {
    color: $colour;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $text-colour-dark;
}

p,
ul {
  color: $text-colour;
}

.text--align-center {
  text-align: center;
}

.carriage-return,
.card__body{
  white-space: pre-wrap;
}