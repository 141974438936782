html {
  /*
   * This will display a scrollbar regardless if the content is scrollable or
   * not. Without this the page shifts once the content becomes scrollable.
   */
  overflow-y: scroll;
  height: 100%;
}

body {
  font-family: "Noto IKEA", "Noto Sans", "Roboto", "Open Sans", sans-serif;
  margin: 0;
  height: 100%;
}

* > * {
  box-sizing: border-box;
}

/*
 * There is styles in SKAPA which precedes the reset of p.
 * This rule overrides that style in order to enable the reset.
 */
p:not(:last-of-type),
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}
