.circle-icon {
  color: #87878b;
  width: 150px;
  height: 150px;
  border-radius: 40%;
  text-align: center;
  line-height: 100px;
  padding: 45px;
  margin-right: 20px;
  display: block;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5); 
  background:rgba(40, 94, 164, 0.1);
}
  
.content {
  display: flex;
  justify-content: center;
  align-items: center;
  transition-property: opacity, left, top, height;
}

.text-content{
 margin-left: 10px;
}

