@each $spacing-name, $spacing in $spacings {
  .padding--left-#{$spacing-name} {
    padding-left: $spacing;
  }

  .padding--#{$spacing-name} {
    padding: $spacing;
  }

  .padding--y-#{$spacing-name} {
    padding-top: $spacing;
    padding-bottom: $spacing;
  }

  .padding--x-#{$spacing-name} {
    padding-right: $spacing;
    padding-left: $spacing;
  }
}

@each $spacing-name, $spacing in $spacings {
  .margin--y-#{$spacing-name} {
    margin-top: $spacing;
    margin-bottom: $spacing;
  }

  .margin--left-#{$spacing-name} {
    margin-left: $spacing;
  }
}
