@each $spacing-name, $spacing in $spacings {
  .inline--space-#{$spacing-name} {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: $spacing;
  }
}

@each $spacing-name, $spacing in $spacings {
  .stack--space-#{$spacing-name} {
    display: flex;
    flex-direction: column;
    gap: $spacing;
  }
}

@each $spacing-name, $spacing in $spacings {
  $excerpt: $spacing / 2;

  .row--space-#{$spacing-name} {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -#{$excerpt};
    margin-right: -#{$excerpt};

    & [class^="column"] {
      width: 100%;
      padding-left: $excerpt;
      padding-right: $excerpt;
      margin-bottom: $spacing;
    }
  }
}

@each $breakpoint-name, $breakpoint in $breakpoints {
  @each $column-size in $column-sizes {
    @media (min-width: $breakpoint) {
      $fraction: $column-size / length($column-sizes);

      .column--#{$breakpoint-name}-#{$column-size} {
        flex-basis: 100% * $fraction;
        max-width: 100% * $fraction;
      }
    }
  }
}
