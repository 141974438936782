.height--100 {
  height: 100%;
}

.height--100vh {
  height: 100vh;
}

@each $spacing-name, $spacing in $spacings {
  .height--space-#{$spacing-name} {
    height: $spacing;
  }
}

.width--100 {
  width: 100%;
}

@each $spacing-name, $spacing in $spacings {
  .width--space-#{$spacing-name} {
    width: $spacing;
  }
}
