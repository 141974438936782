.btn--plain {
  padding: 0;
  border: none;
  cursor: pointer;
  background: none;
  text-align: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}
