.tabs__tab--release {
  &::after {
    border-color: map-get($colours, "notification-type-release");
  }
}

.tabs__tab--issue {
  &::after {
    border-color: map-get($colours, "notification-type-issue");
  }
}

.tabs__tab--service-window {
  &::after {
    border-color: map-get($colours, "notification-type-service-window");
  }
}

.tabs__tab--other {
  &::after {
    border-color: map-get($colours, "notification-type-other");
  }
}
