.timeline {
  display: grid;
  grid-template-columns: auto 1fr;
}

.timeline__header {
  padding: 0 map-get($spacings, 200);
  position: relative;
}

.timeline__line {
  height: 100%;
  background: map-get($colours, "grey-300");
  width: 1px;
  margin: auto;
}

.timeline__dot {
  width: map-get($spacings, 100);
  height: map-get($spacings, 100);
  border-radius: 100%;
  position: absolute;
  left: 50%;
  top: map-get($spacings, 200);
  transform: translate(-50%, 0);
}

@each $colour-name, $colour in $colours {
  .timeline__dot--#{$colour-name} {
    background: $colour;
  }
}
